import { auth } from "auth"
import { v3 } from "backoffice-api"
import { useQueryAll } from "bonzai"
import { Button, Input, Loader } from "materia"
import { QueryBoundary } from "utility-components"
import s from "./DeleteMeView.module.scss"

export const DeleteMeView = () => {
  return (
    <QueryBoundary fallback={<Loader />}>
      <Load />
    </QueryBoundary>
  )
}

const Load = () => {
  const [me, currentCompany] = useData()

  return (
    <div className={s.deleteMeView}>
      <div className={s.deleteMeView__header}>My user info</div>

      <div className={s.deleteMeView__info}>
        {currentCompany.name && (
          <Input label="Company" value={currentCompany.name} disabled />
        )}

        {me.first_name && (
          <Input label="First name" value={me.first_name} disabled />
        )}

        {me.last_name && (
          <Input label="Last name" value={me.last_name} disabled />
        )}

        {me.username && <Input label="Username" value={me.username} disabled />}

        <div className={s.deleteMeView__logout}>
          <Button theme="cancel" onClick={() => auth.logoutAndLogin()}>
            Log out
          </Button>
        </div>
      </div>

      {currentCompany.self_registration && <Delete />}
    </div>
  )
}

const Delete = () => {
  const { mutateAsync, isPending } = v3.anonymizeCurrentUser.useMutation()

  const onDelete = async () => {
    if (window.confirm("Are you sure you want to delete your user data?")) {
      await mutateAsync([])
      auth.logoutAndLogin()
    }
  }

  return (
    <>
      <div className={s.deleteMeView__header}>Delete my user</div>

      <div className={s.deleteMeView__delete}>
        <Button
          theme="delete"
          disabled={isPending}
          onClick={onDelete}
          isLoading={isPending}
        >
          Delete my user
        </Button>
      </div>
    </>
  )
}

const useData = () => {
  return useQueryAll(() => [
    v3.getCurrentUser.useSuspenseQuery([], {
      select: (res) => res.data,
    }),

    v3.getCurrentCompany.useSuspenseQuery([], {
      select: (res) => res.data,
    }),
  ])
}
