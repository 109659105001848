import type { ServerConfig } from "@attensi/frontend-server"

const config = {
  env: [
    "VITE_API_TOKEN",
    "VITE_APP_ENV",
    "VITE_AUTH_URL",
    "VITE_BACKOFFICE_API_URL",
    "VITE_LOGIN_URL",
  ],

  optionalEnv: ["GIT_COMMIT_SHA"],

  envPrefix: "VITE_",

  publicRoot: "./dist",

  isImmutableFile: ({ dir }) => dir.endsWith("assets"),

  contentSecurityPolicy: {
    imgSrc: "https:",
    connectSrc: ["*.attensi.com", "localhost:*"],
    reportUri: "https://auto-report.attensi.com/csp",
    reportOnly: true,
  },
} as const satisfies ServerConfig

export default config
