import { initializeGlobalErrorPopup } from "error-popup"
import "materia-scss/src/reset.scss"
import React from "react"
import ReactDOM from "react-dom/client"
import { App } from "./App"
import { initializeAuth } from "./initialize/initializeAuth"
import { initializeBackofficeApi } from "./initialize/initializeBackofficeApi"

initializeAuth()
initializeBackofficeApi()
initializeGlobalErrorPopup()

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
)
